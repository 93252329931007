<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true" :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '90vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-file" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Detalles Oferta</strong>
        </h4>
      </div>
    </template>
      <div class="w-full text-xxs">
        <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
          <div class="grid grid-rows-4 gap-1 pt-3">
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>Código Cliente/Entidad: </strong>
              </div>
              <div class="col-span-3">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="details.CardCode" disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>Nombre Cliente/Entidad: </strong>
              </div>
              <div class="col-span-3">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="details.CardName" disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>Usuario: </strong>
              </div>
              <div class="col-span-3">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="details.Usuario" disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>Numero de documento: </strong>
              </div>
              <div class="col-span-3">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="details.DocNum" disabled/>
              </div>
            </div>
          </div>
          <div class="grid grid-rows-4 gap-1 pt-1">
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Fecha de Contabilización: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="details.TaxDate" disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Fecha de documento: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="details.DocDate" disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Fecha de vencimiento: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="details.DocDueDate" disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Estado: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="details.DocStatus" disabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 w-full">
        <div>
          <DataTable
            :value="details.detalle"
            class="p-datatable-sm text-xxs"
            showGridlines
            dataKey="LineNum"
            :scrollable="true"
            editMode="row"
            v-model:editingRows="editingRows"
            @row-edit-save="onRowEditSave"
            scrollHeight="370px"
          >
            <template #empty>
              No hay datos para mostrar
            </template>
            <Column
              header="Estado"
              headerStyle="text-align: center;justify-content: center;"
              bodyStyle="text-align: center;justify-content: center;"
              style="min-width:3rem"
            >
              <template #body="{data}">
                <div v-if="data.LineStatus === 'O'">
                  <Button v-tooltip.bottom="'Cerrar Linea'" @click="closeLine(data,details.DocNum)" icon="pi pi-times"
                          v-if="$can('pharmasan.compras.ofertas.cancelar-oferta')"
                          class="p-button-rounded p-button-danger p-button-text"
                          style="min-width:1rem"/>
                  <div v-else>
                    <Badge class="text-xs" style="height: auto;" severity="success" value="Abierta"></Badge>
                  </div>
                </div>
                <div v-else>
                  <Badge class="text-xs" style="height: auto;" severity="danger" value="Cerrada"></Badge>
                </div>
              </template>
            </Column>
            <Column field="LineNum" header="Indice" headerStyle="text-align: center;justify-content: center;" style="min-width:3rem"
                    bodyStyle="text-align: center;justify-content: center;" />
            <Column field="ItemCode" header="Artículo" headerStyle="justify-content: center;"
                    style="min-width:35rem" >
              <template #editor="slotProps">
                <div class="lg:w-78 xl:w-78 sm:w-78">
                  <Dropdown
                    optionLabel="nombreCompleto"
                    optionValue="ItemCode"
                    :options="allMds"
                    @change="onChangeMx(slotProps)"
                    v-model="slotProps.data.ItemCode"
                    placeholder="Escriba 4 letras para empezar a buscar un medicamento..."
                    class="w-full p-inputtext-xs text-xxs"
                    inputClass="p-inputtext-xs text-xxs"
                    panelClass="p-inputtext-xs text-xxs"
                    @click="$h.xxsInput"
                    :filter="true"
                    @filter="getAllMX($event,slotProps)"
                  />
                </div>
              </template>
              <template #body="{data}">
                <div class="grid grid-cols-1 w-full">
                  <div class="flex justify-between items-center my-1">
                    <div>
                      <strong>Código Mx: </strong>{{ data.ItemCode }}
                    </div>
                    <div class="flex gap-2">
                      <Badge v-if="data.ReqMarca === 'SI'" :value="`Req. marca`"
                             class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                      <Badge v-if="data.Controlado" value="Controlado"
                             class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="data.Regulado" value="Regulado"
                             class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="data.cadenaFrio" value="Cadena Frio"
                             class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                    </div>
                  </div>
                  <div>
                    <strong>Nombre: </strong>{{ data.ItemName }}
                  </div>
                </div>
              </template>
            </Column>
            <Column field="" header="Can. Cartas Agotado" headerStyle="text-align: center;justify-content: center;"
                    style="min-width:5rem" bodyStyle="text-align: center;justify-content: center;">
<!--              <template #editor="{data}">
                <span>{{ $h.formatNumber(data.cantidadCartas) }}</span>
              </template>-->
              <template #body="{data}">
                <div>
                  <Button
                    type="button"
                    :disabled="data.cantidadCartas === 0 "
                    class="p-button-xs"
                    :label="data.cantidadCartas.toString()"
                    icon="pi pi-eye"
                    @click="viewCartasMd($event, data.ItemCode)"
                    v-tooltip.bottom="`${data.cantidadCartas === 0 ? 'El Mx no posee cartas': 'visualizar carta(s)'}`"
                    aria-haspopup="true" aria-controls="op_cartas"
                  />
                  <OverlayPanel
                    ref="opCartas"
                    appendTo="body"
                    :showCloseIcon="true"
                    id="op_cartas" style="width: 500px"
                    :breakpoints="{'960px': '90vw'}"
                  >
                    <DataTable
                      :value="cartasList"
                      class="p-datatable-sm text-xs"
                      showGridlines
                      dataKey="nro_carta"
                      responsiveLayout="scroll"
                    >
                      <Column field="nro_carta" header="Num. Carta" headerStyle="justify-content: center;" style="min-width:3rem"/>
                      <Column field="fecha_carta" header="Fec. Carta" headerStyle="justify-content: center;" style="min-width:5rem"/>
                      <Column field="laboratorio" header="Laboratorio" headerStyle="justify-content: center;" style="min-width:12rem"/>
                      <Column field="fecha_disponible" header="Fec. Disponible" headerStyle="justify-content: center;" style="min-width:8rem"/>
                      <Column field="estado" header="Estado" headerStyle="justify-content: center;" style="min-width:5rem"/>
                      <Column field="" header="Acciones " headerStyle="justify-content: center;" style="min-width:5rem"
                              bodyStyle="text-align:center" >
                        <template #body="slotProps">
                          <div>
                            <Button
                              type="button"
                              class="p-button-xs p-button-danger p-button-text"
                              @click="showPdfCarta(slotProps.data.nro_carta)"
                            >
                              <i class="pi pi-file-pdf"></i>
                            </Button>
                          </div>
                        </template>
                      </Column>
                    </DataTable>
                  </OverlayPanel>
                </div>
              </template>
            </Column>
            <Column field="U_PHR_Conversion" header="Conversión - Agrupación" headerStyle="justify-content: center;"
                    style="min-width:20rem">
              <template #editor="{data}">
                <div class="flex flex-col">
                  <div>
                    <strong>Conversión: </strong>{{ $h.formatNumber(data.U_PHR_Conversion) }}
                  </div>
                  <div>
                    <strong>Agrupación: </strong>{{ data.U_PHR_AgrupCom }}
                  </div>
                </div>
              </template>
              <template #body="{data}">
                <div class="flex flex-col">
                  <div>
                    <strong>Conversión: </strong>{{ $h.formatNumber(data.U_PHR_Conversion) }}
                  </div>
                  <div>
                    <strong>Agrupación: </strong>{{ data.U_PHR_AgrupCom }}
                  </div>
                </div>
              </template>
            </Column>
            <Column field="CantidadOferta" header="Cantidad" headerStyle="text-align: center;justify-content: center;"
                    style="min-width:6rem"
                    bodyStyle="text-align: center;justify-content: center;">
              <template #body="{data}">
                <Button type="button" :disabled="data.LineStatus === 'C'" class="p-button-xs" icon="pi pi-pencil"
                        :label="`${data.CantidadOferta}`" @click="openCantidades(data)" aria-haspopup="true"
                        aria-controls="overlay_panel"/>
              </template>
              <template #editor="{data}">
                <Button type="button" class="p-button-xs" icon="pi pi-pencil" :label="`${data.CantidadOferta}`"
                        @click="openCantidades(data)" aria-haspopup="true" aria-controls="overlay_panel"/>
              </template>
            </Column>
            <Column field="Price" header="Precios" align="left" headerStyle="justify-content: center;"
                    style="min-width:8rem">
              <template #body="{data}">
                <div class="flex flex-col">
                  <div>
                    <strong>Precio Unt: </strong>{{ $h.formatCurrency(data.Price) }}
                  </div>
                  <div>
                    <strong>Total Linea: </strong>{{ $h.formatCurrency(data.LineTotal) }}
                  </div>
                </div>
              </template>
              <template #editor="{ data }">
                <div>
                  <InputNumber v-model=data.Price class="p-inputtext-xs w-12" inputId="minmax" mode="currency"
                               currency="COP" :min="1" :minFractionDigits="0"/>
                </div>
              </template>
            </Column>
            <Column field="WarehouseCode" header="Almacén" headerStyle="justify-content: center;"
                    style="min-width:15rem">
              <template #body="{data}">
                <div class="flex flex-col">
                  <div>
                    <strong>Código: </strong>{{ data.WarehouseCode }}
                  </div>
                  <div>
                    <strong>Nombre: </strong>{{ data.WhsName }}
                  </div>
                </div>
              </template>
              <template #editor="{data}">
                <div class="flex flex-col">
                  <div>
                    <strong>Código: </strong>{{ data.WarehouseCode }}
                  </div>
                  <div>
                    <strong>Nombre: </strong>{{ data.WhsName }}
                  </div>
                </div>
              </template>
            </Column>
            <Column header="Acciones" :rowEditor="true"
                    style="min-width:7rem"
                    headerStyle="text-align: center;justify-content: center;"
                    bodyStyle="text-align: center;justify-content: center;"
            >
              <template #body="{data}">
                <Button @click="editingRows = [data]" :disabled="!data.editable" icon="pi pi-pencil"
                        class="p-button-rounded p-button-text p-button-plain"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8 mt-4">
        <div class="grid text-xxs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
          <div class="flex flex-col">
            <p class="mb-2">
              <strong>Comentarios: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs text-xxs"
              disabled
              :autoResize="true"
              v-model="details.Comments"
              rows="2"
              cols="40"
            />
          </div>
          <div class="flex flex-col">
            <p class="mb-2">
              <strong>Entrada de diario: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs text-xxs"
              disabled
              :autoResize="true"
              v-model="details.JrnlMemo"
              rows="2"
              cols="40"
            />
          </div>
        </div>
        <div class="text-xxs lg:col-span-1">
          <div class="flex justify-between border-b-2 pb-3">
            <span class="text-gray-600">Total sin descuento:</span>
            <span>{{ $h.formatCurrency(details.DocTotalBefore) }}</span>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total descuento:</span>
            <span>{{ $h.formatCurrency(details.DocTotal * details.DiscPrcnt / 100) }}</span>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total IVA:</span>
            <span>{{ $h.formatCurrency(details.totalIva) }}</span>
          </div>
          <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
            <span class="font-bold">Total:</span>
            <span>{{ $h.formatCurrency(details.DocTotal) }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-center lg:justify-end align-center mt-5">
        <Button
          v-if="$can('pharmasan.compras.ofertas.cancelar-oferta')"
          icon="pi pi-times"
          label="Cancelar oferta"
          class="p-button-rounded p-button-danger content-center text-sm"
          @click="cancelDocumentSap"/>
      </div>
  </Dialog>
  <modalCant ref="modalCantidades" @refrescar="getLoad"/>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'
import storeConfirmacionPedido from '../../store/ofertas.store'
import Swal from 'sweetalert2'
import { cancelOfertaSap, getListCartasMd } from '../../services/ofertas.service'
import { helper } from '@/utils/helper'
import axios from 'axios'

export default {
  name: 'modalDetalleOferta',
  components: {
    modalCant: defineAsyncComponent(() => import('../components/modalCantidades.vue'))
  },
  emits: ['updatePage'],
  setup (props, { emit }) {
    const modalCantidades = ref()
    const DocEntry = ref(0)
    const allMds = ref([])
    const editingRows = ref([])
    const cartasList = ref([])
    const opCartas = ref()
    // const conversionMx = ref(0)
    const displayModal = ref(false)
    const details = ref({})
    const openModal = async (docEntry) => {
      DocEntry.value = docEntry
      await getLoad()
    }
    const getLoad = async () => {
      await storeConfirmacionPedido.dispatch('getDetailOferta', DocEntry.value).then(({ data }) => {
        details.value = data
        displayModal.value = true
      })
    }
    const openCantidades = async (med) => {
      const params = {
        DocNumOferta: details.value.DocNum,
        LineNumPedido: med.LineNum
      }
      await storeConfirmacionPedido.dispatch('getCantidadesMx', params).then(({ data }) => {
        modalCantidades.value.openModalCant(med, data, DocEntry.value)
      })
    }
    const onRowEditSave = ({ data }) => {
      let onlyItemCode = false
      Swal.fire({
        icon: 'question',
        title: 'Información',
        html:
          `¿Desea modificar la linea : <strong>${data.LineNum}</strong><br> con el medicamento:  <strong>${data.ItemCode} - ${data.ItemName}</strong>
            <br> Precio: <strong> ${helper.formatCurrency(data.Price)}</strong>`,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'No, cancelar',
        confirmButtonText: 'Si, asignar'
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          if (data.ItemCode !== data.ItemCodeOri && data.Price !== data.PriceOri) {
            onlyItemCode = false
          } else {
            onlyItemCode = true
          }
          const params = {
            DocEntry: DocEntry.value,
            data: {
              LineNumOferta: data.LineNum,
              ItemCode: data.ItemCode,
              Price: data.Price,
              onlyItemCode: onlyItemCode
            }
          }
          await storeConfirmacionPedido.dispatch('updateModalDetalle', params).then(({ data }) => {
            getLoad()
          })
        }
      })
    }
    const closeLine = (data, docNum) => {
      let validarEmit = false
      let validarHtml = `¿Desea cerrar la linea:  <strong>${data.LineNum}?</strong><br> Médicamento: <strong>${data.ItemCode} - ${data.ItemName}</strong><br>
            Perteneciente a la oferta: <strong>${docNum}</strong>`
      const validarTodas = details.value.detalle.filter((x) => x.LineStatus === 'O')
      if (details.value.detalle.length === 1 || validarTodas.length === 1) {
        validarEmit = true
        validarHtml += '<br>Esta oferta de compra solo posee una linea o todas las demas lineas se encuentran cerradas, si cierra esta linea todo documento también quedará cerrado<br> ¿Desea continuar?'
      }
      Swal.fire({
        icon: 'question',
        title: 'Esta seguro/a',
        html: validarHtml,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'No, cancelar!',
        confirmButtonText: 'Si, cerrar!'
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          const params = {
            DocEntry: details.value.DocEntry,
            data: {
              LineNumOferta: data.LineNum
            }
          }
          await storeConfirmacionPedido.dispatch('closeLinea', params).then(({ data }) => {
            if (validarEmit) {
              emit('updatePage')
              setTimeout(() => {
                displayModal.value = false
              }, 300)
              setTimeout(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Éxito',
                  text: `${data.message}`,
                  showConfirmButton: true,
                  confirmButtonText: 'Ok'
                })
              }, 400)
            } else {
              getLoad().then((resp) => {
                setTimeout(() => {
                  Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: `${data.message}`,
                    showConfirmButton: true,
                    confirmButtonText: 'Ok'
                  })
                }, 800)
              })
            }
          })
        }
      })
    }
    const onChangeMx = ({ data, index }) => {
      const find = allMds.value.find((a) => a.ItemCode === data.ItemCode)
      details.value.detalle[index].U_PHR_Conversion = find.U_PHR_Conversion
    }
    const getAllMX = async ({ value }, { data }) => {
      const params = {
        agrupacion: data.U_PHR_AgrupCom,
        search: value.toUpperCase(),
        reqMarca: data.ReqMarca,
        U_PHR_Lab: data.U_PHR_Lab
      }
      if (value.length >= 4) {
        await storeConfirmacionPedido.dispatch('searchMxAgrupacion', params).then(({ data }) => {
          allMds.value = data
        })
      }
    }
    const cancelDocumentSap = () => {
      Swal.fire({
        icon: 'question',
        title: '¿ Esta seguro ?',
        text: 'La cancelación del documento es una acción irreversible',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then(async ({ value }) => {
        if (value) {
          await cancelOfertaSap(DocEntry.value).then(({ data }) => {
            if (data.message) {
              Swal.fire({
                icon: 'success',
                title: `Oferta Nro. ${details.value.DocNum} cancelada`,
                text: `${data.message}`,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then(() => {
                displayModal.value = false
                emit('updatePage')
              })
            }
          }).catch((error) => {
            console.log('Error cancelar oferta', error)
          })
        }
      })
    }
    const viewCartasMd = async (event, md) => {
      await opCartas.value.toggle(event)
      await getListCartasMd(md).then(({ data }) => {
        cartasList.value = data
      })
    }
    const showPdfCarta = (id) => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_CARTA_AGOTADO_COMPRAS}/download/downloadCarta/${id}`
      const res = axios.getUri({ url })
      window.open(res, '_blank')
    }
    return {
      openModal,
      displayModal,
      details,
      openCantidades,
      modalCantidades,
      DocEntry,
      editingRows,
      getAllMX,
      allMds,
      onRowEditSave,
      onChangeMx,
      getLoad,
      cancelDocumentSap,
      closeLine,
      viewCartasMd,
      cartasList,
      opCartas,
      showPdfCarta
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.footerModal {
  display: flex;
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-inputnumber-input) {
  width: 7rem;
}

::v-deep(.p-card-content) {
  padding: 0rem;
}
</style>
